@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
html,
body {
  scroll-behavior: smooth;
  position: relative;
  user-select: none;
  font-family: "Montserrat";
}
html::-webkit-scrollbar {
  width: 0;
}

body {
  margin: 0;
  font-family: "Montserrat", serif;
  font-optical-sizing: auto;
  overflow-x: hidden;
}

footer a {
  font-size: 1ch;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#Navbar {
  font-size: 1ch;
}

.header {
  display: flex;
  padding: 2ch 1ch;
  justify-content: space-between;
  font-size: 6rem;
  text-transform: uppercase;
  font-weight: 900;
  color: rgb(224, 224, 224);
}

/* PROJECTS */

.pictures_galery {
  width: 100%;
  height: 50vh;
  display: flex;
  margin: 0 auto;
}
.pictures_galery img {
  width: 0;
  flex-grow: 1;
  object-fit: cover;
  opacity: 0.8;
  transition: 0.5s ease;
}

.pictures_galery img:hover {
  cursor: pointer;
  width: 50%;
  opacity: 1;
  filter: contrast(120%);
}

.pictures_galery img:active {
  width: 100%;
  object-fit: cover;
}

/* INTRODUCTION */
.submarine-animation {
  width: 30ch;
  position: relative;
  transition: all 0.3s ease;
}
.submarine-animation:hover {
  transform: perspective(250px) rotateX(10deg) translateY(-5%) translateZ(0);
}

.submarine-animation::before {
  content: "";
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(
    to bottom,
    transparent 10%,
    rgba(0, 0, 0, 0.5) 50%,
    rgba(0, 0, 0) 95%
  );
  opacity: 0;
  transition: 0.3s all ease;
}
.submarine-animation:hover::before {
  opacity: 1;
}

.submarine-animation:hover a:last-child {
  opacity: 1;
  transform: translateY(30%);
}

.submarine-animation img:first-child {
  box-shadow: 0 60px 60px -60px rgba(0, 30, 255, 0.5);
  border-radius: 50%;
  width: 100%;
  object-fit: cover;
}

.submarine-animation a:last-child {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  transform: translateY(25%);
  transition: 0.3s ease;
  opacity: 0;
}

/* Animations */

.bounce-up-on-hover:hover {
  animation: bounceUp 1s infinite;
}

.animated-bouncing-delayed {
  animation: bounce 3s infinite;
  animation-delay: 1s;
}

.animated-bouncing-delayed-2 {
  animation: bounce 3s infinite;
  animation-delay: 2s;
}

.animated-bouncing {
  animation: bounce 3s infinite;
}

.animated-scaling {
  animation: scale 2s infinite;
}

@keyframes bounceUp {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-8px);
  }
}

@keyframes scale {
  0%,
  100% {
    scale: 105%;
  }
  50% {
    scale: 100%;
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(5px);
  }
}
