/* Floating stack icons rulesets */

.floating-stack-icons {
  top: 0;
  left: 0;
  position: absolute;
  height: 100vh;
  width: 100%;
  z-index: -1;
  pointer-events: none; /* To allow clicks to pass through to elements below */
}

.icon {
  width: 30px;
  height: auto;
  position: absolute;
  opacity: 0.8;
}

.icon-1 {
  animation: floatIcon1 30s linear infinite;
}
.icon-2 {
  animation: floatIcon2 30s linear infinite;
}
.icon-3 {
  animation: floatIcon3 30s linear infinite;
}
.icon-4 {
  animation: floatIcon4 30s linear infinite;
}
.icon-5 {
  animation: floatIcon5 30s linear infinite;
}
.icon-6 {
  animation: floatIcon6 30s linear infinite;
}
.icon-7 {
  animation: floatIcon7 30s linear infinite;
}
.icon-8 {
  animation: floatIcon8 30s linear infinite;
}
.icon-9 {
  animation: floatIcon9 30s linear infinite;
}
.icon-10 {
  animation: floatIcon10 30s linear infinite;
}
.icon-11 {
  animation: floatIcon11 30s linear infinite;
}
.icon-12 {
  animation: floatIcon12 30s linear infinite;
}

/* Define keyframes for floating animation */
@keyframes floatIcon1 {
  0% {
    transform: translate(80vw, 10vh);
  }
  25% {
    transform: translate(90vw, 70vh);
  }
  50% {
    transform: translate(40vw, 90vh);
  }
  75% {
    transform: translate(0vw, 40vh);
  }
  100% {
    transform: translate(80vw, 10vh);
  }
}
@keyframes floatIcon2 {
  0% {
    transform: translate(70vw, 10vh);
  }
  25% {
    transform: translate(0vw, 70vh);
  }
  50% {
    transform: translate(50vw, 90vh);
  }
  75% {
    transform: translate(90vw, 40vh);
  }
  100% {
    transform: translate(70vw, 10vh);
  }
}
@keyframes floatIcon3 {
  0% {
    transform: translate(10vw, 80vh);
  }
  25% {
    transform: translate(80vw, 20vh);
  }
  50% {
    transform: translate(10vw, 10vh);
  }
  75% {
    transform: translate(40vw, 40vh);
  }
  100% {
    transform: translate(10vw, 80vh);
  }
}

@keyframes floatIcon4 {
  0% {
    transform: translate(50vw, 10vh);
  }
  25% {
    transform: translate(80vw, 50vh);
  }
  50% {
    transform: translate(90vw, 90vh);
  }
  75% {
    transform: translate(10vw, 20vh);
  }
  100% {
    transform: translate(50vw, 10vh);
  }
}
@keyframes floatIcon5 {
  0% {
    transform: translate(40vw, 10vh);
  }
  25% {
    transform: translate(10vw, 10vh);
  }
  50% {
    transform: translate(60vw, 90vh);
  }
  75% {
    transform: translate(70vw, 30vh);
  }
  100% {
    transform: translate(40vw, 10vh);
  }
}
@keyframes floatIcon6 {
  0% {
    transform: translate(30vw, 90vh);
  }
  25% {
    transform: translate(50vw, 40vh);
  }
  50% {
    transform: translate(20vw, 10vh);
  }
  75% {
    transform: translate(90vw, 60vh);
  }
  100% {
    transform: translate(30vw, 90vh);
  }
}
@keyframes floatIcon7 {
  0% {
    transform: translate(20vw, 10vh);
  }
  25% {
    transform: translate(60vw, 90vh);
  }
  50% {
    transform: translate(10vw, 10vh);
  }
  75% {
    transform: translate(80vw, 90vh);
  }
  100% {
    transform: translate(20vw, 10vh);
  }
}
@keyframes floatIcon8 {
  0% {
    transform: translate(10vw, 70vh);
  }
  25% {
    transform: translate(40vw, 10vh);
  }
  50% {
    transform: translate(70vw, 90vh);
  }
  75% {
    transform: translate(50vw, 10vh);
  }
  100% {
    transform: translate(10vw, 70vh);
  }
}

@keyframes floatIcon9 {
  0% {
    transform: translate(70vw, 10vh);
  }
  25% {
    transform: translate(90vw, 60vh);
  }
  50% {
    transform: translate(10vw, 90vh);
  }
  75% {
    transform: translate(0vw 20vh);
  }
  100% {
    transform: translate(70vw, 10vh);
  }
}

@keyframes floatIcon10 {
  0% {
    transform: translate(30vw, 10vh);
  }
  25% {
    transform: translate(90vw, 18vh);
  }
  50% {
    transform: translate(10vw, 10vh);
  }
  75% {
    transform: translate(0vw 46vh);
  }
  100% {
    transform: translate(30vw, 10vh);
  }
}

@keyframes floatIcon11 {
  0% {
    transform: translate(5vw, 20vh);
  }
  25% {
    transform: translate(80vw, 30vh);
  }
  50% {
    transform: translate(15vw, 60vh);
  }
  75% {
    transform: translate(5vw, 80vh);
  }
  100% {
    transform: translate(90vw, 20vh);
  }
}

@keyframes floatIcon12 {
  0% {
    transform: translate(50vw, 15vh);
  }
  25% {
    transform: translate(80vw, 70vh);
  }
  50% {
    transform: translate(20vw, 80vh);
  }
  75% {
    transform: translate(5vw, 30vh);
  }
  100% {
    transform: translate(50vw, 15vh);
  }
}
