/* NAVBAR BUTTONS */
.navbar-button {
  padding: 5px 10px; /* Simplified padding */
  color: #000000;
  transition: 0.5s;
}

.button-active {
  background-color: #838383;
  color: white;
}

/* FOOTER */
footer {
  padding: 40px 20px; /* Combines py-10 and px-8 */
  text-align: center;
  font-size: 12px;
  color: #9ca3af; /* Equivalent to text-gray-400 */
  background-color: #1f2937; /* Equivalent to bg-gray-900 */
}

footer p {
  padding: 20px 0; /* Equivalent to py-5 */
  margin: 0;
}
